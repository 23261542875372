<template>
  <div class="font-sans antialiased">
    <nav class="flex items-center justify-between flex-wrap nav-color p-6">
      <div class="flex items-center flex-no-shrink text-white mr-6">
        <span class="font-semibold text-xl tracking-tight">Gombácsi Péter</span>
      </div>
      <div class="block sm:hidden">
        <button @click="toggle" class="flex items-center px-3 py-2 border rounded text-gray-200 border-gray-300 hover:text-white hover:border-white">
          <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
        </button>
      </div>
      <div :class="open ? 'block': 'hidden'" class="mt-3 border-t-[1px] sm:border-t-[0px] border-gray-400 w-full flex-grow sm:flex sm:items-center sm:w-auto">
        <div class="text-xl sm:flex-grow border-b-[1px] sm:border-b-[0px] border-gray-400">
          <router-link to="/" @click="close" class="no-underline block mt-2 mb-2 sm:inline-block sm:mt-0 text-gray-200 hover:text-white mr-4">Főoldal</router-link>
        </div>
        <div>
          <router-link to="nyilatkozat" @click="close" class="no-underline inline-block px-4 py-2 leading-none border rounded text-gray-300 border-gray-300 hover:border-transparent hover:text-teal hover:text-white hover:border-white mt-4 sm:mt-0">B kategóriás tájékoztató</router-link>
        </div>
      </div>
    </nav>
  </div>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
      open: false
    }
  },
  methods: {
    toggle() {
      this.open = !this.open
    },
    close() {
      this.open = false
    }
  }
}
</script>

<style>
  .nav-color {
    background-color: #1d2d44;
  }
</style>
