<template>
  <div class="flex justify-center mt-5 sm:mt-10">
    <div class="border-2 container max-w-[800px] bg-slate-400 m-5 glass">
      <div class="flex m-5 sm:m-10 text-base sm:text-2xl font-bold">Kapcsolat</div>
      <table class="min-w-full mb-5 sm:mb-10">
        <tbody>
          <tr>
            <td class="whitespace-nowrap pr-1 sm:pl-10 py-4 sm:py-8 md:pl-20">
              <font-awesome-icon class="grow text-base sm:text-2xl ml-5" icon="fa-solid fa-phone" />
            </td>
            <td class="whitespace-nowrap pr-1 sm:pr-10 py-4 sm:py-8 md:pr-20">
              <div class="grow-[2] text-base sm:text-2xl ml-5">06 70 670 1978</div>
            </td>
          </tr>
          <tr>
            <td class="whitespace-nowrap pr-1 sm:pl-10 py-4 sm:py-8 md:pl-20">
              <font-awesome-icon class="grow text-base sm:text-2xl ml-5" icon="fa-solid fa-envelope" />
            </td>
            <td class="whitespace-nowrap pr-1 sm:pr-10 py-4 sm:py-8 md:pr-20">
              <div class="grow-[2] text-base sm:text-2xl ml-5">gombacsi.peter@gmail.com</div>
            </td>
          </tr>
          <tr>
            <td class="whitespace-nowrap pr-1 sm:pl-10 py-4 sm:py-8 md:pl-20">
              <font-awesome-icon class="grow text-base sm:text-2xl ml-5" icon="fa-solid fa-window-maximize" />
            </td>
            <td class="whitespace-nowrap pr-1 sm:pr-10 py-4 sm:py-8 md:pr-20">
              <div class="grow-[2] text-base sm:text-2xl ml-5">www.gombavezetes.hu</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppMain'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .container {
    max-width: 800px;
  }
  .glass {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.6);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.7px);
    -webkit-backdrop-filter: blur(8.7px);
    border: 1px solid rgba(255, 255, 255, 0.27);
  }
</style>
