import { createApp } from 'vue'
import App from './App.vue'
import './styles/app.css';
import { createRouter, createWebHashHistory } from "vue-router";

import AppMain from './components/AppMain.vue'
import AppNyilatkozat from './components/AppNyilatkozat.vue'

const routes = [
    {
        path : "/",
        component : AppMain
    },
    {
        path : "/nyilatkozat",
        component : AppNyilatkozat
    }
]

const router = createRouter({
    history : createWebHashHistory(),
    routes
});


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faPhone, faEnvelope, faWindowMaximize } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faPhone)
library.add(faWindowMaximize)
library.add(faEnvelope)

createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(router)
    .mount('#app');
