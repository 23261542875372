<template>
  <div class="bg-nyilatkozat flex overflow-auto">
    <div class="doc-width bg-white pl-45 pr-10 pt-20 pb-10 m-10">
      <!-- <p class=MsoNormal style='margin-right:-23.4pt'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal align=center style='margin-right:-23.4pt;text-align:center'><span
      lang=HU style='font-size:16.0pt'>&nbsp;</span></p>

      <p class=MsoBodyTextIndent align=center style='margin-left:85.05pt;text-align:
      center;text-indent:-77.95pt'><b><span lang=HU><span style='text-decoration:
      none'>&nbsp;</span></span></b></p>

      <p class=MsoBodyTextIndent align=center style='margin-left:85.05pt;text-align:
      center;text-indent:-77.95pt'><b><span lang=HU style='font-size:18.0pt'><span
      style='text-decoration:none'>&nbsp;</span></span></b></p> -->

      <p class="underline" style='margin-left:85.05pt;text-indent:-77.95pt'><b><span
      lang=HU style='font-size:18.0pt'>Vállalkozási feltétel és Írásos tájékoztató
      „B” kategória.</span></b></p>

      <!-- <p class=MsoNormal align=center style='text-align:center'><b><u><span lang=HU
      style='font-size:18.0pt'><span style='text-decoration:none'>&nbsp;</span></span></u></b></p> -->

      <p class=MsoNormal><b><i><span lang=HU style='font-size:16.0pt'>&nbsp;</span></i></b></p>

      <p class=MsoNormal><b><i><span lang=HU style='font-size:16.0pt'>&nbsp;</span></i></b></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU style='font-size:
      14.0pt'>1. Képzőszerv:                 <b>Gombácsi Péter egyéni vállalkozó</b></span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU style='font-size:
      14.0pt'>    Rövidített neve:           <b>„Gomba Autósiskola”</b></span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU style='font-size:
      14.0pt'>    Székhelye/ Címe:          4030 Debrecen Áchim András u. 8/A</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU style='font-size:
      14.0pt'>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU style='font-size:
      14.0pt'>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU style='font-size:
      14.0pt'>2.  Cég formája:                       egyéni vállalkozás</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU style='font-size:
      14.0pt'>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in;line-height:150%'><span lang=HU
      style='font-size:14.0pt;line-height:150%'>                      </span></p>

      <p class=MsoNormal style='margin-right:.5in;line-height:150%'><span lang=HU
      style='font-size:14.0pt;line-height:150%'>3. Nyilvántartási szám:          51475671</span></p>

      <p class=MsoNormal style='margin-right:.5in;line-height:150%'><span lang=HU
      style='font-size:14.0pt;line-height:150%'>    Felnőttképzési nyilvántartási száma:
      B/2020/008120</span></p>

      <p class=MsoNormal style='margin-right:.5in;line-height:150%'><span lang=HU
      style='font-size:14.0pt;line-height:150%'>   Adószáma:                                68252977-1-29</span></p>

      <p class=MsoNormal style='margin-right:.5in;line-height:150%'><span lang=HU
      style='font-size:14.0pt;line-height:150%'>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU style='font-size:
      14.0pt'>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU style='font-size:
      14.0pt'>4.  Iskolavezetője:                                Lévai Erzsébet Mária</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU style='font-size:
      14.0pt'>     Elérhetősége:                               06-30/338-1838</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU style='font-size:
      14.0pt'>                                                         </span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU style='font-size:
      14.0pt'>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in;line-height:150%'><span lang=HU
      style='font-size:14.0pt;line-height:150%'>5. Ügyfélfogadó iroda:</span></p>

      <p class=MsoNormal style='margin-right:.5in;line-height:150%'><span lang=HU
      style='font-size:14.0pt;line-height:150%'>    Címe:                                                  4030
      Debrecen Áchim András u. 8/A. </span></p>

      <p class=MsoNormal style='margin-right:.5in;line-height:150%'><span lang=HU
      style='font-size:14.0pt;line-height:150%'>    Telefonszáma:                                    06-70/670-1978</span></p>

      <p class=MsoNormal style='margin-right:.5in;line-height:150%'><span lang=HU
      style='font-size:14.0pt;line-height:150%'>    Nyitvatartás:                                      Kedd-Szerda-Csütörtök-Péntek</span></p>

      <p class=MsoNormal style='margin-right:.5in;line-height:150%'><span lang=HU
      style='font-size:14.0pt;line-height:150%'>                                                                  18-20
      óráig</span></p>

      <p class=MsoNormal style='margin-right:.5in;line-height:150%'><span lang=HU
      style='font-size:14.0pt;line-height:150%'>                                                        
      </span></p>

      <p class=MsoNormal style='margin-right:.5in;line-height:150%'><span lang=HU
      style='font-size:14.0pt;line-height:150%'>     Web:                                                </span><span
      lang=HU><a href="http://www.gombavezetes.hu"><span style='font-size:14.0pt;
      line-height:150%'>www.gombavezetes.hu</span></a></span></p>

      <p class=MsoNormal style='margin-right:.5in;line-height:150%'><span lang=HU
      style='font-size:14.0pt;line-height:150%'>     Email cím:                                      </span><span
      lang=HU><a href="mailto:gombacsi.peter@gmail.com"><span style='font-size:14.0pt;
      line-height:150%'>gombacsi.peter@gmail.com</span></a></span></p>

      <p class=MsoNormal style='margin-right:.5in;line-height:150%'><span lang=HU
      style='font-size:14.0pt;line-height:150%'>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in;line-height:150%'><span lang=HU
      style='font-size:14.0pt;line-height:150%'>     Tanpálya:                                       Debrecen,
      Monostorpályi út 37.</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU style='font-size:
      14.0pt'>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU style='font-size:
      14.0pt'>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU style='font-size:
      14.0pt'>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>6<u>. Tanfolyamra
      történő felvétel előírása</u></span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>Képzésre az vehető fel
      :</span></p>

      <p class=MsoNormal style='margin-top:0in;margin-right:.5in;margin-bottom:0in;
      margin-left:55.5pt;margin-bottom:.0001pt;text-indent:-19.5pt'><span lang=HU>-<span
      style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span lang=HU>megszerezni kívánt vezetői engedély kiadásához
      szükséges, jogszabályban meghatározott életkort betöltötte vagy tanfolyami
      képzés esetén annál legfeljebb 6 hónapnál fiatalabb.</span></p>

      <p class=MsoNormal style='margin-top:0in;margin-right:.5in;margin-bottom:0in;
      margin-left:55.5pt;margin-bottom:.0001pt'><span lang=HU>„ B „ kategória esetén
      előirt életkort aki a 16 és fél évet betöltötte ,</span></p>

      <p class=MsoNormal style='margin-top:0in;margin-right:.5in;margin-bottom:0in;
      margin-left:55.5pt;margin-bottom:.0001pt'><span lang=HU> 18 év alatt szülői/
      gondviselői engedély .</span></p>

      <p class=MsoNormal style='margin-top:0in;margin-right:.5in;margin-bottom:0in;
      margin-left:.75in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=HU>-<span
      style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span lang=HU>megfelel a külön jogszabályban meghatározott
      közlekedésbiztonsági feltételeknek,</span></p>

      <p class=MsoNormal style='margin-top:0in;margin-right:.5in;margin-bottom:0in;
      margin-left:.75in;margin-bottom:.0001pt'><span lang=HU>a tanulószerződést
      megkötötte.</span></p>

      <p class=MsoListParagraph style='margin-top:0in;margin-right:.5in;margin-bottom:
      0in;margin-left:.75in;margin-bottom:.0001pt;text-align:justify;text-indent:
      -.25in'><span lang=HU>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span lang=HU>A szerződés teljesítése az első részletfizetéssel
      kezdődik, és az utolsó sikeres gyakorlati vizsgával ér véget, illetve a
      szerződéstől elállás esetén elszámolás után.</span></p>

      <p class=MsoNormal style='margin-right:.5in;text-align:justify'><span lang=HU> </span></p>

      <p class=MsoNormal style='margin-top:0in;margin-right:.5in;margin-bottom:0in;
      margin-left:.5in;margin-bottom:.0001pt'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU> Az előírt
      egészségügyi alkalmassági vizsgálat „B” kategóriánál: I. csoportú orvosi
      alkalmassági.</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><u><span lang=HU>7. „B” kategóriás
      tanfolyam </span></u></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-top:0in;margin-right:.5in;margin-bottom:0in;
      margin-left:141.6pt;margin-bottom:.0001pt;text-indent:35.4pt'><u><span lang=HU>Elméleti
      tanfolyam és vizsga</span></u></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>A gépjárművezetői tanfolyam
      tantermi elméleti órái 45 percesek, 10 perc szünettel. Egy tanfolyami napon
      négy elméleti óra tartható. </span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>A tanfolyam végét
      elméleti számítógépes vizsga követi.</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>A tanulónak az
      elméleti tanfolyam első foglalkozásától számított 9 hónapon belül meg kell
      kezdenie az elméleti vizsgát és 12 hónapon belül sikeres vizsgát kell tennie.</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>A tanuló számára a
      képzési rész elvégzése akkor igazolható, ha az egyes tantárgyak tanteremben
      történő elméleti oktatására vonatkozó kötelező óraszám 10%-ánál kevesebbet
      hiányzott. Az óraszámok 10%-nál kevesebb hiányzást önálló felkészüléssel is
      lehet pótolni. Ettől magasabb óraszámú hiányzás esetén a tanuló számára - azon
      témakörökből, melyeken a tanuló nem vett részt - pótfoglalkozást kell tartani.
      A pótfoglalkozást a tanrendbe be kell illeszteni, és a tanrend módosítására
      vonatkozók szerint be kell jelenteni.<br>
      Lehetőség van az elméleti tanfolyam elvégzésére online képzés keretében is,
      ahol a tanuló saját ütemezésében, de megadott időkereten belül végezheti el az
      elméleti képzést. </span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>Elméleti képzés az
      együttműködési megállapodásban megjelölt képzőszervnél.</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal align=center style='margin-right:.5in;text-align:center'><u><span
      lang=HU>Vezetési gyakorlat</span></u></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU style='font-size:
      14.0pt'>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in;text-align:justify'><span lang=HU>Gyakorlati
      vezetés a sikeres elméleti vizsga után kezdhető meg abban az esetben, ha már a
      tanuló rendelkezik 2 példányban a képzési igazolással melyet az elméleti tanfolyamot
      szervező képzőszerv bocsájtott a részére. Ezt a „ Gomba Autósiskola”
      iskolavezetője átvette és a tanulóval a képzés folytatásáról a felnőttképzési
      szerződést megkötötte.</span></p>

      <p class=MsoNormal style='margin-right:.5in;text-align:justify'><span lang=HU>A
      gyakorlati órák 50 percesek. Alapoktatás alatt napi két óra, főoktatás során legfeljebb
      napi négy óra tartható 1 órás szünet beiktatásával. Az előzetes időpont
      egyeztetés után az oktató köteles a tanulóval a megbeszélt időben és helyen
      megjelenni és az oktató járművet biztosítani. A megbeszélt időponttól számított
      20 perc a kötelező várakozási idő mindkét fél részről. A gyakorlati óra
      időpontjának módosítását vagy lemondását 24 órával a megkezdés előtt jelezni
      kell, ellenkező esetben az óradíj felszámolásra kerül, de a kötelező óraszámba
      nem számít bele.</span></p>

      <p class=MsoNormal style='margin-right:.5in;text-align:justify'><span lang=HU>Az
      oktató részéről történő mulasztás esetén az órát más időpontban meg kell
      tartani.</span></p>

      <p class=MsoNormal style='margin-right:.5in;text-align:justify'><span lang=HU>A
      gyakorlati órák díjait, valamint a teljes tandíjat a táblázat tartalmazza.</span></p>

      <p class=MsoNormal style='margin-right:.5in;text-align:justify'><span lang=HU>Az
      alapoktatási óraszám levezetése után (9 óra) kezdődhet el a főoktatás. A
      főoktatásban kötelezően előírt óraszám 20 óra, mely a következőképpen oszlik
      el: városi oktatás 14 óra, országúti vezetés 4 óra és éjszakai vezetés 2 óra. A
      forgalmi vizsga bocsájtás feltétele a kötelezően előírt minimum 29 gyakorlati
      óra levezetése és az 580 km menettávolság teljesítése. </span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>Sikeres KRESZ
      vizsgától számított 2 éven belül sikeres forgalmi vizsgát kell tennie a
      tanulónak, ha ez nem történik meg akkor az addig megszerzett eredményei
      elvesznek.</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><u><span lang=HU>8. Vizsgára
      bocsátás feltételei</span></u></p>

      <p align=center style='margin-top:15.0pt;margin-right:.5in;margin-bottom:15.0pt;
      margin-left:7.5pt;text-align:center'><a name=10></a><a name=pr119></a><span
      lang=HU>A gyakorlati vizsgára bocsátás feltételei<a name=11></a><a name=pr140></a></span></p>

      <p style='margin-top:0in;margin-right:.5in;margin-bottom:0in;margin-left:0in;
      text-align:justify'><span lang=HU>&nbsp;</span></p>

      <p style='margin-top:0in;margin-right:.5in;margin-bottom:0in;margin-left:7.35pt'><span
      lang=HU> Gyakorlati vizsgára az bocsátható, aki<a name=pr141></a></span></p>

      <p style='margin-top:0in;margin-right:.5in;margin-bottom:0in;margin-left:7.35pt'><i><span
      lang=HU> A </span></i><span lang=HU>vezetői engedély kiadásának feltételéül
      előírt 17. életévét betöltötte és a <a name=pr143></a><a name=pr144></a>kötelezően
      előírt minimum 29 gyakorlati óra vezetést és az 580 km menettávolságot
      teljesítése. Forgalmi vezetési gyakorlat esetén öt vizsga tehető. Öt sikertelen
      vizsga esetén rendkívüli PÁV vizsgát kell tenni.</span></p>

      <p style='margin-top:0in;margin-right:.5in;margin-bottom:0in;margin-left:0in'><span
      lang=HU>&nbsp;</span></p>

      <p align=center style='margin-top:0in;margin-right:.5in;margin-bottom:0in;
      margin-left:7.5pt;text-align:center;text-indent:12.0pt'><span lang=HU>&nbsp;</span></p>

      <p align=center style='margin-top:0in;margin-right:.5in;margin-bottom:0in;
      margin-left:7.5pt;text-align:center;text-indent:12.0pt'><span lang=HU>A vizsgák
      érvényessége, a vizsgaigazolás kiállítása</span></p>

      <p align=center style='margin-top:0in;margin-right:.5in;margin-bottom:0in;
      margin-left:7.5pt;text-align:center;text-indent:12.0pt'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><a name=pr127></a><a name=pr136></a><span
      lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:-59.4pt'><span lang=HU>A KAV Közlekedési
      Alkalmassági és Vizsgaközpont sikeres forgalmi vizsga után elektronikus úton</span></p>

      <p class=MsoNormal style='margin-right:-59.4pt'><span lang=HU>megküldi a
      Hajdú-Bihar Megyei Kormányhivatal okmányirodáinak a sikerességet </span></p>

      <p class=MsoNormal style='margin-right:-59.4pt'><span lang=HU>igazoló
      vizsgaigazolást, mely után a tanuló elindíthatja a vezetői engedélyének
      kiváltását. Kérésre a KAV </span></p>

      <p class=MsoNormal style='margin-right:-59.4pt'><span lang=HU>nyomtatott
      formában is elkészíti.</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><u><span lang=HU>9. Hiányzás
      pótlásának módja:</span></u></p>

      <p class=MsoNormal style='margin-right:.5in'><u><span lang=HU><span
      style='text-decoration:none'>&nbsp;</span></span></u></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>   Hiányzás esetén
      az órákat pótolni kell, melynek díja megegyezik az alap óradíjjal.</span></p>

      <p class=MsoNormal style='margin-right:-59.4pt'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:-59.4pt'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:-59.4pt'><u><span lang=HU>10. „B”
      Tanfolyamdíj, és vizsgáztatási díj táblázat</span></u></p>

      <p class=MsoNormal style='margin-right:-59.4pt'><span lang=HU>&nbsp;</span></p>

      <div align=center>

      <table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
      style='border-collapse:collapse;border:none'>
      <tr style='height:26.25pt'>
        <td width=164 colspan=2 valign=top style='width:123.0pt;border:solid windowtext 1.0pt;
        padding:0in 3.5pt 0in 3.5pt;height:26.25pt'>
        <p class=MsoNormal style='line-height:200%'><span lang=HU>&nbsp;</span></p>
        </td>
        <td width=84 colspan=2 valign=top style='width:63.0pt;border:solid windowtext 1.0pt;
        border-left:none;padding:0in 3.5pt 0in 3.5pt;height:26.25pt'>
        <p class=MsoNormal align=center style='text-align:center;line-height:200%'><span
        lang=HU>Óra</span></p>
        </td>
        <td width=108 colspan=2 valign=top style='width:81.0pt;border:solid windowtext 1.0pt;
        border-left:none;padding:0in 3.5pt 0in 3.5pt;height:26.25pt'>
        <p class=MsoNormal align=center style='text-align:center;line-height:200%'><span
        lang=HU>Ft/óra</span></p>
        </td>
        <td width=121 valign=top style='width:90.75pt;border:solid windowtext 1.0pt;
        border-left:none;padding:0in 3.5pt 0in 3.5pt;height:26.25pt'>
        <p class=MsoNormal align=center style='text-align:center;line-height:200%'><span
        lang=HU>Tandíj összesen</span></p>
        </td>
        <td width=137 valign=top style='width:102.85pt;border:solid windowtext 1.0pt;
        border-left:none;padding:0in 3.5pt 0in 3.5pt;height:26.25pt'>
        <p class=MsoNormal align=center style='text-align:center;line-height:200%'><span
        lang=HU>Vizsgáztatási díj</span></p>
        </td>
      </tr>
      <tr style='height:26.25pt'>
        <td width=163 valign=top style='width:122.25pt;border-top:none;border-left:
        solid black 1.5pt;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
        padding:0in 5.4pt 0in 5.4pt;height:26.25pt'>
        <p class=MsoNormal style='line-height:200%'><span lang=HU>Járműkezelés</span></p>
        </td>
        <td width=84 colspan=2 valign=top style='width:63.0pt;border-top:none;
        border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
        padding:0in 5.4pt 0in 5.4pt;height:26.25pt'>
        <p class=MsoNormal align=center style='text-align:center;line-height:200%'><span
        lang=HU>9</span></p>
        </td>
        <td width=108 colspan=2 valign=top style='width:81.0pt;border-top:none;
        border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
        padding:0in 5.4pt 0in 5.4pt;height:26.25pt'>
        <p class=MsoNormal align=center style='text-align:center;line-height:200%'><span
        lang=HU>10.000.-</span></p>
        </td>
        <td width=122 colspan=2 valign=top style='width:91.5pt;border-top:none;
        border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
        padding:0in 5.4pt 0in 5.4pt;height:26.25pt'>
        <p class=MsoNormal align=center style='text-align:center;line-height:200%'><span
        lang=HU>90,000-.Ft</span></p>
        </td>
        <td width=137 valign=top style='width:102.85pt;border-top:none;border-left:
        none;border-bottom:solid windowtext 1.0pt;border-right:solid black 1.5pt;
        padding:0in 5.4pt 0in 5.4pt;height:26.25pt'>
        <p class=MsoNormal align=center style='text-align:center;line-height:200%'><span
        lang=HU>-</span></p>
        </td>
      </tr>
      <tr style='height:25.5pt'>
        <td width=163 valign=top style='width:122.25pt;border-top:none;border-left:
        solid black 1.5pt;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
        padding:0in 5.4pt 0in 5.4pt;height:25.5pt'>
        <p class=MsoNormal style='line-height:200%'><span lang=HU>Forgalom</span></p>
        </td>
        <td width=84 colspan=2 valign=top style='width:63.0pt;border-top:none;
        border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
        padding:0in 5.4pt 0in 5.4pt;height:25.5pt'>
        <p class=MsoNormal align=center style='text-align:center;line-height:200%'><span
        lang=HU>21</span></p>
        </td>
        <td width=108 colspan=2 valign=top style='width:81.0pt;border-top:none;
        border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
        padding:0in 5.4pt 0in 5.4pt;height:25.5pt'>
        <p class=MsoNormal align=center style='text-align:center;line-height:200%'><span
        lang=HU>10.000.-</span></p>
        </td>
        <td width=122 colspan=2 valign=top style='width:91.5pt;border-top:none;
        border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
        padding:0in 5.4pt 0in 5.4pt;height:25.5pt'>
        <p class=MsoNormal align=center style='text-align:center;line-height:200%'><span
        lang=HU>210.000.-Ft</span></p>
        </td>
        <td width=137 valign=top style='width:102.85pt;border-top:none;border-left:
        none;border-bottom:solid windowtext 1.0pt;border-right:solid black 1.5pt;
        padding:0in 5.4pt 0in 5.4pt;height:25.5pt'>
        <p class=MsoNormal align=center style='text-align:center;line-height:200%'><span
        lang=HU>11.000.-Ft</span></p>
        </td>
      </tr>
      <tr style='height:21.75pt'>
        <td width=163 valign=top style='width:122.25pt;border-top:none;border-left:
        solid black 1.5pt;border-bottom:solid black 1.5pt;border-right:solid windowtext 1.0pt;
        padding:0in 5.4pt 0in 5.4pt;height:21.75pt'>
        <p class=MsoNormal style='line-height:200%'><span lang=HU>ÖSSZESEN</span></p>
        </td>
        <td width=84 colspan=2 valign=top style='width:63.0pt;border-top:none;
        border-left:none;border-bottom:solid black 1.5pt;border-right:solid windowtext 1.0pt;
        padding:0in 5.4pt 0in 5.4pt;height:21.75pt'>
        <p class=MsoNormal style='line-height:200%'><span lang=HU>&nbsp;</span></p>
        </td>
        <td width=108 colspan=2 valign=top style='width:81.0pt;border-top:none;
        border-left:none;border-bottom:solid black 1.5pt;border-right:solid windowtext 1.0pt;
        padding:0in 5.4pt 0in 5.4pt;height:21.75pt'>
        <p class=MsoNormal style='line-height:200%'><span lang=HU>&nbsp;</span></p>
        </td>
        <td width=122 colspan=2 valign=top style='width:91.5pt;border-top:none;
        border-left:none;border-bottom:solid black 1.5pt;border-right:solid windowtext 1.0pt;
        padding:0in 5.4pt 0in 5.4pt;height:21.75pt'>
        <p class=MsoNormal align=center style='text-align:center;line-height:200%'><span
        lang=HU>300.000.-Ft</span></p>
        </td>
        <td width=137 valign=top style='width:102.85pt;border-top:none;border-left:
        none;border-bottom:solid black 1.5pt;border-right:solid black 1.5pt;
        padding:0in 5.4pt 0in 5.4pt;height:21.75pt'>
        <p class=MsoNormal align=center style='text-align:center;line-height:200%'><span
        lang=HU>11.000.-Ft</span></p>
        </td>
      </tr>
      <tr height=0>
        <td width=163 style='border:none'></td>
        <td width=1 style='border:none'></td>
        <td width=83 style='border:none'></td>
        <td width=1 style='border:none'></td>
        <td width=107 style='border:none'></td>
        <td width=1 style='border:none'></td>
        <td width=121 style='border:none'></td>
        <td width=137 style='border:none'></td>
      </tr>
      </table>

      </div>

      <p class=MsoNormal style='margin-right:.5in;text-align:justify'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in;text-align:justify'><span lang=HU>A
      tanfolyam időtartama alatt bekövetkezett jelentős, az oktatást érintő
      árnövekedés esetén, ezzel arányos tandíj változás jogát fenntartjuk.</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:-59.4pt'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><u><span lang=HU>Tanfolyami díj
      és Vizsgadíj</span></u></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><span lang=HU>- Tanfolyamdíj
      befizetése történhet részletekben vagy egy összegben is.</span></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><span lang=HU>-A vizsgadíj
      befizetése a KAV Közlekedési Alkalmassági és Vizsgaközpont</span></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><span lang=HU>pénztárába
      történik. 4024 Debrecen Széchenyi u. 46/b A. épület.</span></p>

      <p class=MsoNormal style='margin-right:-59.4pt'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:-59.4pt'><span lang=HU>-Elsősegély vizsgára
      jelentkezés:</span></p>

      <p class=MsoNormal style='margin-right:-59.4pt'><span lang=HU> Hajdú-Bihar
      Megyei Vöröskereszt címe: </span></p>

      <p class=MsoNormal><span lang=HU>Debrecen Hatvan u. 37 sz. Tel: 316-330,
      316-679. </span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><u><span lang=HU>11.Elsősegélynyújtó
      vizsga alóli felmentés:</span></u></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>Az elsősegély
      tanfolyam és vizsga alóli mentességről a 24/2005 (IV.21) GKM rendelet
      4.1.4.2.-ban</span></p>

      <p class=MsoNormal><span lang=HU>rendelkezik.</span></p>

      <p class=MsoNormal><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><u><span lang=HU>12.Tanuló
      áthelyezés </span></u></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in;text-align:justify'><span lang=HU>A
      tanuló kezdeményezésére a képzőszerv kiállítja 3 példányban a Képzési
      Igazolást. A kitöltött igazolás egy példánya a képzőszervnél marad, a másik két
      példányt a tanulónak átadja. Az áthelyezési igény esetén a képzőszerv csak az
      igénybevett szolgáltatást számolja el. A képzőszerv köteles 3 munkanapon belül
      a Képzési Igazolást kiállítani.</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>A képzőszerhez való
      érkezés esetén: A tanulónál lévő képzési igazolás 2.példányát átveszi , melyet
      8 munkanapon belül köteles átadni a megyeileg illetékes vizsgaközponthoz.</span></p>

      <p class=MsoNormal><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><u><span lang=HU>13. Engedélyező
      hatóság és felügyeleti szerv: </span></u></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><u><span lang=HU>Építési és
      Közlekedési Minisztérium</span></u></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><span lang=HU>Közúti
      Gépjármű-közlekedési Hatósági Főosztály</span></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><span lang=HU>      Cím:</span><span
      lang=HU style='font-size:13.5pt;font-family:"Verdana",sans-serif;color:black'> </span><span
      lang=HU style='color:black'>1054 Budapest, Alkotmány utca 5</span></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><span lang=HU>      Központi
      postafiók címe: <span style='color:black'>1358 Budapest, Pf. 14.</span><br>
            E-mail: kepzes-kghf@ekm.gov.hu</span></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><u><span lang=HU>KAV
      Közlekedési Alkalmassági és Vizsgaközpont Nonprofit Korlátolt Felelősségű
      Társaság:</span></u></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><span lang=HU>      Központi
      cím: 1033 Budapest Polgár u. 8-10 sz. </span></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><span lang=HU>      Székhely
      cím: Debrecen, Széchenyi u. 46 sz.</span></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><span lang=HU>      E-mail:
      info@kavk.hu</span></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><span lang=HU>            </span></p>

      <p class=MsoNormal style='margin-right:.5in'><u><span lang=HU>14. A vizsgázó
      jogai és kötelezettségei:</span></u></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>A szerződésben
      vállalt kötelezettségek és vállalási feltételben leírtak betartása,
      foglalkozásokon való részvétel, tanfolyami és vizsgadíjak időbeni befizetése.
      Az oktatásokon és vizsgán vezető képes állapotban megjelenni,( alkohol, drog ,
      kábítószer, kábító hatású gyógyszer hatása alatt nem áll.)</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>amennyiben ennek
      kétsége felmerül az oktatást az oktató felfüggesztheti  a tanulónak a tanórát
      ki kell fizetni.  Az oktatás során felmerülő problémával az </span><span
      lang=HU style='font-size:14.0pt'>iskolavezetőhöz </span><span lang=HU> fordulhat.
      Ha a probléma nem oldódik meg, akkor a KAV Közlekedési Alkalmassági és
      Vizsgaközponthoz (4024 Debrecen Széchenyi u. 46/B) lehet panaszt tenni.<a
      name=pr1365></a></span></p>

      <p style='margin-top:0in;margin-right:.5in;margin-bottom:0in;margin-left:7.5pt;
      text-align:justify;text-indent:12.0pt'><span lang=HU>&nbsp;</span></p>

      <p style='margin-top:0in;margin-right:.5in;margin-bottom:0in;margin-left:7.5pt;
      text-align:justify;text-indent:12.0pt'><span lang=HU>A vizsgázó a
      vizsgabiztosra a vizsgakiírástól számított legfeljebb 10 percet köteles várni.
      Ennél több késés esetén a vizsgázó kérheti a vizsga új időpontban megtartását.
      Ebben az esetben a vizsgázó vizsgadíj befizetése nélkül tehet újabb vizsgát<a
      name=pr1366></a>.</span></p>

      <p style='margin-top:4.5pt;margin-right:.5in;margin-bottom:0in;margin-left:
      0in;text-align:justify'><span lang=HU>A vizsgán meg nem jelenés</span></p>

      <p style='margin-top:0in;margin-right:.5in;margin-bottom:0in;margin-left:7.5pt;
      text-align:justify;text-indent:12.0pt'><a name=pr1367></a><span lang=HU>A
      vizsgára kiírt időpontban meg nem jelenő, vagy az engedélyezettnél többet késő
      vizsgázó újabb vizsgadíj befizetése nélkül csak az esetben tehet újabb vizsgát,
      ha távolmaradását</span></p>

      <p style='margin-top:0in;margin-right:.5in;margin-bottom:0in;margin-left:7.5pt;
      text-align:justify;text-indent:12.0pt'><a name=pr1368></a><span lang=HU>-
      legkésőbb a vizsgát megelőző 5. napon a közlekedési hatóságnak személyesen vagy
      írásban bejelenti,</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>Szolgáltatási
      panasszal fordulhat a HBM. Közigazgatási Hivatal Fogyasztóvédelmi </span></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><span lang=HU>Felügyelőséghez.</span></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:-23.4pt'><u><span lang=HU>15. Egyéb
      tudnivalók</span></u></p>

      <p class=MsoNormal style='margin-right:.5in;text-align:justify'><span lang=HU>A
      tanfolyamdíjat a tanulószerződésben van rögzítve.</span></p>

      <p class=MsoNormal style='margin-right:.5in;text-align:justify'><span lang=HU>Kedvezményes
      tandíjnál a kedvezmény az alapórákra értendő. A pótórákért és pótvizsgákért a
      díjat külön kell megfizetni. A pótvizsgadíj és a pályadíj megegyezik az alap
      vizsgadíjakkal. </span></p>

      <p class=MsoNormal style='margin-right:.5in;text-align:justify'><span lang=HU>Kedvezményesen
      indított tanfolyamok esetén a Képzőszervnél elvégzett elméleti és gyakorlati
      oktatás végösszegéből vonható le. Ha a tanuló máshol folytatja a tanulmányát, a
      kedvezményt elveszíti.</span></p>

      <p class=MsoNormal style='margin-right:.5in;text-align:justify'><span lang=HU>A
      képzés során bekövetkezett baleset esetén a felmerülő káresemény
      következményeit a képzőszerv viseli. Szándékos vagy nagyfokú gondatlanság miatt
      bekövetkezett káresetért a vétkest kell felelősségre vonni.</span></p>

      <p class=MsoNormal style='margin-right:.5in;text-align:justify'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in'><u><span lang=HU><span
      style='text-decoration:none'>&nbsp;</span></span></u></p>

      <p class=MsoNormal style='margin-right:.5in'><u><span lang=HU> Panaszkezelés
      módja</span></u></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>A tanulók sérelem
      esetén az iskolavezetőhöz fordulhatnak. Szóban vagy írásban panaszt tehetnek.</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>A  Panaszlapot az ügyfélfogadó
      helyiségben a tanuló kérésére rendelkezésre kell bocsátani .</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>Az iskolavezetőnek a
      panaszt ki  kell vizsgálnia. A vizsgálat eredményéről a tanulónak írásban</span></p>

      <p class=MsoNormal style='margin-right:.5in'><span lang=HU>vissza kell jelezni.
      </span></p>

      <p class=MsoNormal style='margin-right:.5in;text-align:justify'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in;text-align:justify'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:.5in;text-align:justify'><span lang=HU>A
      jelentkező a Írásos Tájékoztatót megismerte, átvette és ezt a Tanuló szerződés
      aláírásával igazolta.</span></p>

      <p class=MsoNormal style='margin-right:-59.4pt'><span lang=HU style='font-size:
      10.0pt'>&nbsp;</span></p>

      <p class=MsoNormal style='margin-top:0in;margin-right:-59.4pt;margin-bottom:
      0in;margin-left:35.4pt;margin-bottom:.0001pt;text-indent:35.4pt'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-top:0in;margin-right:-59.4pt;margin-bottom:
      0in;margin-left:.5in;margin-bottom:.0001pt'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:-59.4pt'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:-59.4pt'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:-59.4pt'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-right:-59.4pt'><span lang=HU>Debrecen, 2023. április
      17.                            </span></p>

      <p class=MsoNormal style='margin-top:0in;margin-right:-59.4pt;margin-bottom:
      0in;margin-left:283.2pt;margin-bottom:.0001pt'><span lang=HU>&nbsp;</span></p>

      <p class=MsoNormal style='margin-top:0in;margin-right:-59.4pt;margin-bottom:
      0in;margin-left:283.2pt;margin-bottom:.0001pt'><span lang=HU> Lévai Erzsébet</span></p>

      <p class=MsoNormal style='margin-top:0in;margin-right:-59.4pt;margin-bottom:
      0in;margin-left:247.8pt;margin-bottom:.0001pt;text-indent:35.4pt'><span
      lang=HU>  Iskolavezető</span></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppNyilatkozat',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .bg-nyilatkozat {
    background-color: #3e5c76;
  }
  .pl-45 {
    padding-left: 45px;
  }
  .doc-width {
    min-width: 595.3pt;
    max-width: 595.3pt;
    min-height: 841.9pt;
  }
</style>
